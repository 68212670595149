@use '@/scss/underscore' as _;

.link {
  display: inline-flex;
  max-width: fit-content;
  text-decoration: none;
  user-select: none;
  border-color: transparent;
  font: var(--font-body-2);
  color: var(--color-text-link);
  gap: _.unit(1);
  cursor: pointer;

  &.trailingIcon {
    flex-direction: row-reverse;
  }

  &:active {
    color: var(--color-primary-pressed);
  }

  &:disabled {
    color: var(--color-disabled);
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  > svg {
    display: inline-block;
    vertical-align: baseline;
  }
}
