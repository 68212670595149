@use '@/scss/underscore' as _;

.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contentContainer {
  flex: 1;
  overflow-y: auto;
  padding-bottom: _.unit(6);
}

.content {
  margin: 0 auto;
  max-width: 858px;
  border-radius: 16px;
  padding: _.unit(12);
  background-color: var(--color-layer-1);
  margin-bottom: _.unit(4);
  display: flex;
  flex-direction: column;
}

.title {
  font: var(--font-title-1);
  margin-top: _.unit(3);
}

.description {
  font: var(--font-body-2);
  color: var(--color-text-secondary);
  margin-top: _.unit(3);
}
