@use '@/scss/underscore' as _;

.container {
  position: relative;

  .header {
    flex-shrink: 0;
    margin-bottom: _.unit(4);
  }

  .hidden {
    display: none;
  }
}
