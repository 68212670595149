@use '@/scss/underscore' as _;

.tabs {
  display: flex;
  align-items: center;
  gap: _.unit(4);
  margin-bottom: _.unit(4);


  .tab {
    display: flex;
    align-items: center;
    border-radius: 100px;
    height: 34px;
    color: var(--color-text);
    background: var(--color-layer-1);
    border: 1px solid var(--color-surface-5);
    padding: 0 _.unit(3);
    transition: none;

    svg {
      width: 16px;
      height: 16px;
      object-fit: cover;
      color: var(--color-text-link);
    }

    &:active {
      background: var(--color-overlay-primary-pressed);
    }

    &:not(:disabled):not(:active):hover {
      background: var(--color-overlay-primary-hover);
    }

    &.active {
      background: var(--color-inverse-primary);
      color: var(--color-white);
      border-color: var(--color-inverse-primary);
      cursor: default;

      svg {
        color: var(--color-button-icon);
      }

      &:not(:disabled):not(:active):hover {
        background: var(--color-inverse-primary);
      }
    }
  }
}

.tabContent {
  display: none;

  &.active {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: _.unit(4);
  }

  .description {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    padding: 0 _.unit(1);
  }
}


/** Flexbox */
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexGrow {
  flex: 1;
  min-height: 200px;
}
