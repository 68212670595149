@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: _.unit(2) _.unit(3) _.unit(2) _.unit(4);
  user-select: none;

  &:hover {
    background-color: var(--color-hover);
  }

  .closeIcon {
    margin-left: _.unit(1);
  }
}
