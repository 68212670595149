@use '@/scss/underscore' as _;

.container {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-divider);
  border-radius: 8px;
  padding: _.unit(4);
  gap: _.unit(2);

  .title {
    flex: 1;
    font: var(--font-label-2);
  }

  .actions {
    display: flex;
    gap: _.unit(4);
    align-items: center;

    .icon {
      max-width: _.unit(4);
      max-height: _.unit(4);
    }

    .danger {
      color: var(--color-error);

      &:focus-visible {
        outline: 2px solid var(--color-danger-focused);
      }

      &:not(:disabled):hover {
        background: var(--color-overlay-danger-hover);
      }
    }
  }
}
