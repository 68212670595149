@use '@/scss/underscore' as _;

.form {
  width: 100%;
  margin-top: _.unit(6);

  .titleSelector {
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
  }

  .option {
    min-height: 100px;
  }
}
