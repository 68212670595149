@use '@/scss/underscore' as _;

.error {
  color: var(--color-error);
}

.codeEditor {
  min-height: 400px;
  flex-grow: 1;
}
