@use '@/scss/underscore' as _;

.mainContent {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    flex-shrink: 0;
    margin-bottom: _.unit(4);
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: _.unit(4);
  }

  .description {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    margin-bottom: _.unit(3);
  }
}
