@use '@/scss/underscore' as _;

.drawerContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  flex: 0 0 64px;
  display: flex;
  align-items: center;
  padding: 0 _.unit(6);
  background-color: var(--color-layer-1);
  font: var(--font-title-2);
  color: var(--color-text);
  box-shadow: var(--shadow-1);
  @include _.z-index(front);

  .separator {
    border-left: 1px solid var(--color-border);
    height: 20px;
    width: 0;
    margin: 0 _.unit(5);
  }
}

.cardGroup {
  flex: 1;
  padding: _.unit(6);
}

.guide {
  flex: 1;
  overflow: hidden;
  padding: _.unit(6);
}
