const general = {
    placeholder: 'Placeholder',
    submit: 'Submit',
    skip: 'Skip',
    next: 'Next',
    back: 'Back',
    retry: 'Try again',
    done: 'Done',
    search: 'Search',
    search_placeholder: 'Search',
    clear_result: 'Clear results',
    save: 'Save',
    save_changes: 'Save changes',
    saved: 'Saved',
    discard: 'Discard',
    loading: 'Loading...',
    redirecting: 'Redirecting...',
    add: 'Add',
    added: 'Added',
    cancel: 'Cancel',
    confirm: 'Confirm',
    check_out: 'Check out',
    create: 'Create',
    set_up: 'Set up',
    customize: 'Customize',
    enable: 'Enable',
    reminder: 'Reminder',
    edit: 'Edit',
    delete: 'Delete',
    deleted: 'Deleted',
    more_options: 'MORE OPTIONS',
    close: 'Close',
    copy: 'Copy',
    copying: 'Copying',
    copied: 'Copied',
    required: 'Required',
    add_another: 'Add another',
    create_another: 'Create another',
    deletion_confirmation: 'Are you sure you want to delete this {{title}}?',
    settings_nav: 'Settings',
    unsaved_changes_warning: 'You have made some changes. Are you sure you want to leave this page?',
    leave_page: 'Leave page',
    stay_on_page: 'Stay on page',
    type_to_search: 'Type to search',
    got_it: 'Got it',
    continue: 'Continue',
    page_info: '{{min, number}}-{{max, number}} of {{total, number}}',
    learn_more: 'Learn more',
    tab_errors: '{{count, number}} errors',
    skip_for_now: 'Skip for now',
    remove: 'Remove',
    visit: 'Visit',
    join: 'Join',
    try_now: 'Try now',
    multiple_form_field: '(Multiple)',
    demo: 'Demo',
    unnamed: 'Unnamed',
    view: 'View',
    open: 'Open',
    hide: 'Hide',
    unknown_error: 'Unknown error, please try again later.',
    select: 'Select',
    contact_us_action: 'Contact us',
    description: 'Description',
    name: 'Name',
    add_field: 'Add {{field}}',
    create_field: 'Create {{field}}',
    edit_field: 'Edit {{field}}',
    delete_field: 'Delete {{field}}',
    coming_soon: 'Coming soon',
    or: 'Or',
};
export default Object.freeze(general);
