const organization_details = {
    page_title: 'Detalhes da organização',
    delete_confirmation: 'Uma vez excluída, todos os membros perderão sua associação e cargos nesta organização. Essa ação não pode ser desfeita.',
    organization_id: 'ID da organização',
    settings_description: 'Organizações representam as equipes, clientes empresariais e empresas parceiras que podem acessar suas aplicações.',
    name_placeholder: 'O nome da organização, não é necessário ser único.',
    description_placeholder: 'Uma descrição da organização.',
    member: 'Membro',
    member_other: 'Membros',
    add_members_to_organization: 'Adicionar membros à organização {{name}}',
    add_members_to_organization_description: 'Encontre usuários apropriados pesquisando nome, e-mail, telefone ou ID do usuário. Membros existentes não são mostrados nos resultados da pesquisa.',
    add_with_organization_role: 'Adicionar com função(ões) na organização',
    user: 'Usuário',
    authorize_to_roles: 'Autorizar {{name}} a acessar os seguintes cargos:',
    edit_organization_roles: 'Editar cargos da organização',
    edit_organization_roles_of_user: 'Editar cargos da organização de {{name}}',
    remove_user_from_organization: 'Remover usuário da organização',
    remove_user_from_organization_description: 'Uma vez removido, o usuário perderá sua associação e cargos nesta organização. Essa ação não pode ser desfeita.',
    search_user_placeholder: 'Pesquisar por nome, e-mail, telefone ou ID do usuário',
    at_least_one_user: 'Pelo menos um usuário é necessário.',
    custom_data: 'Dados personalizados',
    custom_data_tip: 'Dados personalizados é um objeto JSON que pode ser usado para armazenar dados adicionais associados à organização.',
    invalid_json_object: 'Objeto JSON inválido.',
};
export default Object.freeze(organization_details);
