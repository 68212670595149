@use '@/scss/underscore' as _;

.regionText {
  font: var(--font-label-2);

  .comingSoon {
    margin-left: _.unit(1);
    font: var(--font-body-2);
    color: var(--color-text-secondary);
  }
}
