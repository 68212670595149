@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  background: var(--color-base);
  align-items: center;
  justify-content: center;
  overflow-y: auto;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 540px;
    padding: _.unit(35) _.unit(17.5);
    gap: _.unit(6);
    background: var(--color-layer-1);
    border-radius: 16px;
    box-shadow: var(--shadow-1);
    text-align: center;
    white-space: pre-wrap;

    .logo {
      height: 36px;
    }

    .title {
      font: var(--font-title-2);
    }

    .description {
      font: var(--font-body-2);
      color: var(--color-text-secondary);
    }

    .button {
      width: 100%;
    }
  }
}
