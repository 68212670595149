@use '@/scss/underscore' as _;

.container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: _.unit(4);
  min-width: 540px;
  gap: _.unit(4);

  &.withSubmitActionBar {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .fields {
    flex-grow: 1;

    > :not(:first-child) {
      margin-top: _.unit(4);
    }
  }
}
