@use '@/scss/underscore' as _;

.description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: _.unit(0.5);
}

.envTagRadioGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: _.unit(4);
}
