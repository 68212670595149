@use '@/scss/underscore' as _;

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: _.unit(4);
  padding-bottom: _.unit(6);

  .header {
    flex-shrink: 0;
  }
}

